export const data = [
  {
    banner: require('src/assets/images/quem-somos-sm.png'),
    bannerMD: require('src/assets/images/quem-somos-md.png'),
    bannerLG: require('src/assets/images/quem-somos-lg.png'),
    bannerXL: require('src/assets/images/quem-somos-xl.png'),
    titlePage: '<span>Análises e recomendações para melhorar os seus investimentos</span>',
    descriptionPage:
      "<span class='d-block mb-4'>O portal de conteúdos da Inter Invest existe com um único propósito: orientar os seus investimentos para que você faça seu dinheiro render da melhor forma, com o retorno e a segurança adequados aos seus objetivos.</span>",

    subDescriptionOne:
      "<span class='d-block mb-4'>Não importa se você está começando agora ou se já é experiente, temos análises técnicas, relatórios aprofundados e materiais didáticos para atender ao seu grau de conhecimento e perfil de investidor.</span>",

    subDescriptionTwo:
      "<span class='d-block mb-4'><strong>E tudo isso gratuitamente.</strong> Não tem pegadinha, letra miúda ou oferta especial. Aqui, você tem acesso a toda a inteligência de mercado dos times de Research e de Portfólios do Inter sem precisar gastar o dinheiro que você deveria estar aplicando.</span>",

    subDescriptionThree:
      "<span class='d-block mb-4'>O Inter simplifica também a vida do investidor.</span>",
  },
]
