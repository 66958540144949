import React from 'react'

import Layout from 'src/components/Layout'
import PageTitle from 'src/components/PageTitle'

import pageContext from './pageContext.json'
import { data } from './_data'

// componets

const ParaQueViemos = () => {
  const breadcrumb = {
    path: [
      { link: '/', text: 'Home' },
      { link: '/quem-somos', text: 'Quem Somos' },
    ],
  }

  return (
    <Layout pageContext={pageContext}>
      <div className="container">
        <div className="row">
          <PageTitle breadcrumb={breadcrumb} pageTitle={pageContext.pageTitle} />
          <div className="px-2">
            <div className="d-md-none">
              <img
                loading="lazy"
                src={data[0].banner}
                alt="imagem com uma mão segurando uma vela de faíscas"
              />
            </div>
            <div className="d-none d-md-block d-lg-none">
              <img
                loading="lazy"
                src={data[0].bannerMD}
                alt="imagem com uma mão segurando uma vela de faíscas"
              />
            </div>
            <div className="d-none d-lg-block d-xl-none">
              <img
                loading="lazy"
                src={data[0].bannerLG}
                alt="imagem com uma mão segurando uma vela de faíscas"
              />
            </div>
            <div className="d-none d-xl-block">
              <img
                loading="lazy"
                src={data[0].bannerXL}
                alt="imagem com uma mão segurando uma vela de faíscas"
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-4 mt-md-5">
          <div className="col-12 col-lg-10">
            <div className="mb-5">
              <h2
                className="fs-24 fs-md-40 lh-50 lh-md-65 text-grayscale--500 mb-4"
                dangerouslySetInnerHTML={{ __html: data[0].titlePage }}
              />
              <p
                className="fs-14 fs-md-18 lh-17 lh-md-26 fw-400 text-grayscale--400"
                dangerouslySetInnerHTML={{ __html: data[0].descriptionPage }}
              />
            </div>
            <div className="mb-5">
              <p
                className="fs-14 fs-md-18 lh-17 lh-md-26 fw-400 text-grayscale--400"
                dangerouslySetInnerHTML={{ __html: data[0].subDescriptionOne }}
              />
            </div>
            <div className="mb-5">
              <p
                className="fs-14 fs-md-18 lh-17 lh-md-26 fw-400 text-grayscale--400"
                dangerouslySetInnerHTML={{ __html: data[0].subDescriptionTwo }}
              />
            </div>
            <div className="mb-5">
              <p
                className="fs-14 fs-md-18 lh-17 lh-md-26 fw-400 text-grayscale--400"
                dangerouslySetInnerHTML={{ __html: data[0].subDescriptionThree }}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ParaQueViemos
